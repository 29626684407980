<template>
  <b-row class="justify-content-center">
    <b-col md="6" xl="6">
      <b-card title="Detail Bukti Pemrek">
        <b-overlay :show="loading" rounded="sm">
          <b-form-group label="Nama Lengkap" label-for="name">
            <b-form-input
              id="name"
              type="text"
              v-model="form.name"
              :state="state.name"
              required
            />
          </b-form-group>
          <b-form-group label="No Hp" label-for="phone">
            <b-form-input
              id="phone"
              maxlength="13"
              type="number"
              v-model="form.phone"
              :state="state.phone"
              required
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
          </b-form-group>
          <b-form-group label="Tanggal Terdaftar" label-for="registerDate">
            <flat-pickr
              v-model="form.proven_at"
              class="form-control"
              :config="datepicker"
              :state="state.proven_at"
              required
            />
          </b-form-group>
          <b-form-group label="Deskripsi" label-for="description">
            <b-form-textarea id="note" v-model="form.note" />
          </b-form-group>
          <b-form-group label="Nomor Rekening" label-for="account_no">
            <b-form-input
              id="account_no"
              type="text"
              v-model="form.account_no"
              :state="state.account_no"
            />
          </b-form-group>
          <b-form-group label="Pakai Migor" label-for="had_migor">
            <b-form-select
              v-model="form.had_migor"
              :state="state.had_migor"
              :options="migorOptions"
            />
          </b-form-group>
          <b-row class="mt-2">
            <b-col>
              <b-form-group
                label="Bukti Foto"
                label-for="image"
                class="input-image"
              >
                <div class="text-center">
                  <image-uploader
                    :preview="true"
                    :className="[
                      'fileinput',
                      'custom-file-input',
                      { 'fileinput--loaded': hasImage },
                    ]"
                    :quality="0.5"
                    :maxSize="2048"
                    :debug="0"
                    :autoRotate="true"
                    outputFormat="string"
                    @input="setImage"
                  >
                    <label for="fileInput" slot="upload-label" class="d-block">
                      <figure>
                        <feather-icon icon="CameraIcon" size="35" />
                      </figure>
                      <span class="upload-caption">{{
                        hasImage ? "Replace" : "Click to upload"
                      }}</span>
                    </label>
                  </image-uploader>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Foto Tambahan"
                label-for="imageAdd"
                class="input-image-add"
              >
                <div class="text-center">
                  <image-uploader
                    :preview="true"
                    :className="[
                      'fileinputAdd',
                      'custom-file-input',
                      { 'fileinput--loaded': hasImageAdd },
                    ]"
                    :quality="0.5"
                    :maxSize="2048"
                    :debug="0"
                    :autoRotate="true"
                    outputFormat="string"
                    @input="setImageAdd"
                  >
                    <label
                      for="fileinputAdd"
                      slot="upload-label"
                      class="d-block"
                    >
                      <figure>
                        <feather-icon icon="CameraIcon" size="35" />
                      </figure>
                      <span class="upload-caption">{{
                        hasImageAdd ? "Replace" : "Click to upload"
                      }}</span>
                    </label>
                  </image-uploader>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button block class="mt-2" variant="success" @click="onSend"
            >Kirim</b-button
          >
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormFile,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import ImageUploader from "vue-image-upload-resize";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormFile,
    flatPickr,
    ImageUploader,
    BOverlay,
    BFormSelect,
  },
  data() {
    return {
      loading: false,
      hasImage: false,
      hasImageAdd: false,
      datepicker: {
        dateFormat: "d-m-Y",
      },
      state: {
        name: null,
        phone: null,
        proven_at: null,
        image: null,
        image_additional: null,
        note: null,
        account_no: null,
        had_migor: null,
      },
      form: {
        name: "",
        phone: "",
        proven_at: "",
        image: null,
        image_additional: null,
        note: "",
        account_no: null,
        had_migor: null,
      },
      migorOptions: [
        { value: 0, text: "Tidak" },
        { value: 1, text: "Ya" },
      ],
    };
  },
  created() {},
  methods: {
    setImage(output) {
      this.hasImage = true;
      this.form.image = output;
    },
    setImageAdd(output) {
      this.hasImageAdd = true;
      this.form.imageAdd = output;
    },
    validation() {
      if (
        this.form.name.length == 0 ||
        this.form.name == " " ||
        this.form.name == "  " ||
        this.form.name == "   "
      ) {
        this.$bvToast.toast("Nama Lengkap tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.phone.length == 0) {
        this.$bvToast.toast("No Hp tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.phone.length < 9) {
        this.$bvToast.toast("No Hp min harus 10 digit", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.proven_at.length == 0) {
        this.$bvToast.toast("Tanggal terdaftar tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.image == null) {
        this.$bvToast.toast("Bukti foto tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (
        this.form.name.length > 0 &&
        this.form.name != " " &&
        this.form.name != "  " &&
        this.form.name != "   " &&
        this.form.phone.length > 9 &&
        this.form.proven_at.length > 0 &&
        this.form.image != null
      ) {
        return true;
      }
    },
    onSend() {
      if (this.validation()) {
        console.log(this.form);
        this.loading = true;
        this.$http
          .post("/report-aladin/add", this.form)
          .then((res) => {
            this.loading = false;
            this.$bvToast.toast("Berhasil", {
              title: "Berhasil",
              variant: "success",
              solid: true,
              autoHideDelay: 1000,
            });
            this.$router.push("/pemrek-aladin-verify");
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 1000,
            });
          });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
    onContext(ctx) {
      var $selected = ctx.selectedYMD;
      var $arr = $selected.split("-");
      this.form.proven_at = $arr[2] + "-" + $arr[1] + "-" + $arr[0];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
